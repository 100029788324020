import React, { useState } from 'react';
import NextLink from '../NextLink';
import useDropdownStaggeredFadeAnimation from '@src/hooks/useDropdownStaggeredFadeAnimation';
import isEmpty from '@src/utils/isEmpty';
import { ListItemPropTypes } from './TopNavigation.types';
const ListItem = ({
  className,
  page,
  pathname
}: ListItemPropTypes) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    listRef,
    containerRef
  } = useDropdownStaggeredFadeAnimation({
    isOpen
  });
  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);
  React.useEffect(() => {
    setIsOpen(false);
  }, [pathname]);
  return <li className={`group hidden h-[100%] items-center xl:flex xl:locale-es:hidden xl:locale-fr:hidden 3xl:locale-es:flex 3xl:locale-fr:flex ${className}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} data-sentry-component="ListItem" data-sentry-source-file="ListItem.tsx">
      {/* Dropdown trigger */}
      <NextLink href={page?.slug} locale='en' className={`text-header-copy font-[400] text-body-copy--dark transition-colors duration-300 ease-in-out group-hover:text-primary ${pathname.includes(page?.slug) ? 'font-[500]' : ''}`} data-sentry-element="NextLink" data-sentry-source-file="ListItem.tsx">
        {page?.pageName}
      </NextLink>

      {/* Dropdown content */}
      {!isEmpty({
      value: page?.subPagesCollection?.items
    }) && <div ref={containerRef} className={`pointer-events-none invisible absolute left-1/2 top-full z-10 w-[20rem] -translate-x-1/2 transform overflow-hidden border border-border bg-background-secondary opacity-0 transition-opacity duration-300 2xl:!text-[1.6rem]`}>
          <div ref={listRef} className='flex flex-col py-[1rem]'>
            {page?.subPagesCollection?.items?.map(sub => <NextLink locale='en' key={sub?.sys?.id} href={sub?.slug} className='cursor-pointer px-[1.5rem] py-[1rem] font-[400] text-body-copy--dark transition-colors duration-300 ease-in-out hover:font-[500] hover:text-primary xl:px-[2rem]'>
                {sub?.pageName}
              </NextLink>)}
          </div>
        </div>}
    </li>;
};
export default ListItem;