'use client';

import React from 'react';
import { AnnouncementBarResponseTypes } from '@src/graphql/queries.types';
import { GQL_FETCH_ANNOUNCEMENT_ENTRIES } from '@src/graphql/queries';
import { IS_PREVIEW } from '@src/typescriptGlobals/constants';
import RenderBodyCopy from '../RenderBodyCopy';
import useEbookSlideAnimate from '@src/hooks/useEbookSlideAnimate';
import fetchAPI from '@src/utils/fetchAPI';
import { AnnouncementBarPropsTypes, AnnouncementTypes } from './AnnouncementBar.types';
const AnnouncementBar: React.FC<AnnouncementBarPropsTypes> = ({
  params: {
    locale
  }
}) => {
  const ebookSlideRef = useEbookSlideAnimate();
  const [eBooks, setEBooks] = React.useState<AnnouncementTypes>();
  React.useEffect(() => {
    fetchAPI<AnnouncementBarResponseTypes>({
      query: GQL_FETCH_ANNOUNCEMENT_ENTRIES?.loc?.source?.body ?? '',
      variables: {
        locale: locale?.toUpperCase(),
        preview: IS_PREVIEW
      }
    }).then(res => setEBooks(res.eBooks?.items?.[0]));
  }, []);
  const multipleEbook = eBooks?.topicCollection?.items?.length! > 1;
  return <div ref={ebookSlideRef} id='announcement-bar' className={`text-white invisible absolute top-[7.4rem] z-[100] flex w-full items-center justify-center gap-[0.4rem] overflow-hidden  bg-primary text-center text-[1.6rem] font-[500] text-body-copy--light shadow-lg [&_*_p]:!leading-[2] ${multipleEbook ? 'py-[1rem]' : 'py-[0.35rem]'}`} data-sentry-component="AnnouncementBar" data-sentry-source-file="AnnouncementBar.tsx">
      {eBooks?.title && <p className={`self-center text-left lg:text-[1.8rem] ${multipleEbook ? 'ml-[-24rem]' : ''}`}>
          {eBooks?.title}
        </p>}

      <div className={`relative flex items-center text-center`}>
        {eBooks?.topicCollection?.items?.map(eBook => {
        return <RenderBodyCopy key={eBook?.sys?.id} bodyCopy={eBook?.bodyCopy?.json?.content} className={`mt-[-0.2rem] line-clamp-1 text-left text-body-copy--light underline hover:text-[#ECEAE8] [&>p>a]:!text-body-copy--light [&>p]:truncate locale-es:[&>p]:w-[10rem] locale-fr:[&>p]:w-[10rem] sm:[&>p]:!w-[100%] ${multipleEbook ? 'invisible absolute w-[30rem]' : ''}`} />;
      })}
      </div>
    </div>;
};
export default AnnouncementBar;