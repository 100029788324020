'use client';

import React from 'react';
import { usePathname, useRouter } from 'next/navigation';
import Button from '../Button';
import isEmpty from '@src/utils/isEmpty';
import { SideNavPropTypes } from './TopNavigation.types';
const SideNavigation: React.FC<SideNavPropTypes> = ({
  list,
  onToggleMobileNav,
  isHamburger
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const onNavigate = ({
    slug,
    languageCode
  }: {
    slug: string;
    languageCode: string;
  }) => {
    return () => {
      const path = languageCode === 'en' ? slug : `/${languageCode}${slug}`;
      router.push(path);
      onToggleMobileNav();
    };
  };
  return <ul className={`fixed bottom-[0] left-[0] z-[10000000000] mx-auto mt-[8rem] flex h-[calc(100*var(--vh)-6.4rem)] w-[100vw] max-w-[160rem] flex-col overflow-y-auto bg-background-secondary px-[2.4rem] py-[2.4rem] pt-[1rem] transition-all duration-300 ease-in-out md:px-[6.3rem] lg:px-[6.4rem] xl:hidden xl:px-[12.8rem] xl:locale-es:flex xl:locale-fr:flex 3xl:px-[4rem] 3xl:locale-es:hidden 3xl:locale-fr:hidden 4xl:px-[0]
     ${isHamburger ? 'visible opacity-100' : 'invisible opacity-0'}
    `} data-sentry-component="SideNavigation" data-sentry-source-file="SideNavigation.tsx">
      {list?.map((item, index) => {
      return <li key={`${item?.sys?.id}-nav-item`} className='cursor-pointer'>
            <div className={`block	text-left md:hover:bg-background-secondary ${!isEmpty({
          value: item?.subPagesCollection?.items
        }) || item?.slug === 'https://security.valdperformance.com/login' ? 'border-b-[0.1rem] border-border' : ''} ${list?.length - 1 === index ? 'border-none' : ''} `}>
              <div onClick={onNavigate({
            slug: item?.slug,
            languageCode: item?.languageCode?.toLowerCase()
          })} className={`${!isEmpty({
            value: item?.subPagesCollection?.items
          }) ? 'mb-[-1rem]' : ''}  flex items-center justify-between py-[1.6rem]`}>
                <span className={`text-[1.6rem] font-[400] ${item?.slug === '/contact' ? '!text-primary' : 'text-body-copy--dark'}  ${pathname.includes(item?.slug) ? 'font-[500]' : ''}`}>
                  {item?.pageName}
                </span>
              </div>
              {!isEmpty({
            value: item?.subPagesCollection?.items
          }) && <ul className={`pl-[1rem] ${item?.slug === '/products' ? 'md:grid md:grid-cols-2 ' : ''}`}>
                  {item?.subPagesCollection?.items?.map(item => {
              return <li key={`${item?.sys?.id}-sidenav-item`} className='cursor-pointer p-[1rem] font-[400] text-body-copy--dark'>
                        <div onClick={onNavigate({
                  slug: item?.slug,
                  languageCode: item?.languageCode?.toLowerCase()
                })}>
                          <span className={`block text-[1.6rem] ${pathname?.includes(item?.slug) ? 'font-[500]' : ''}`}>
                            {item?.pageName}
                          </span>
                          {item?.description && <span className='mt-[0.5rem] block text-[1.4rem] font-[300] leading-[2.2rem]'>
                              {item?.description}
                            </span>}
                        </div>
                      </li>;
            })}
                  {item?.slug === '/products' && <Button tertiary href='/products' align='justify-start' btnText='All Products' containerClassName={`pl-[1.5rem] my-[2rem] ${item?.pageName}`} />}
                </ul>}
            </div>
          </li>;
    })}
    </ul>;
};
export default SideNavigation;